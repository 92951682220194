// Special utilities for screen readers

// Show for Screen Readers Only
// To visually hide content, while still allowing assistive technology to read it,
// add the class show-for-sr.
$important: config(important);


.u-show-for-sr,
html .u-show-for-sr {
  position: absolute $important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
