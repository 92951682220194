$important: config(important);

.u-font-light-semibold {
  font-weight: 500 $important;
}

.u-font-semibold {
  font-weight: 600 $important;
}

.u-font-bold {
  font-weight: 700 $important;
}

.u-font-normal {
  font-weight: normal $important;
}

// Can be merged with .u-font-bold
.u-font-black {
  font-weight: 800 $important;
}
