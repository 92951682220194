
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$important: config(important);

// :global {
  // [TODO:] Refactor styles
.notifications-tc {
  top: 0 $important;
  bottom: auto $important;
  margin: 0 auto $important;
  left: 50% $important;
  margin-left: auto $important;
  transform: translate(-50%, 0) $important;
  width: 955px $important;
  max-width: 90%;
  z-index: 2 * $gl-z-index-modal$important;
}
// }
