// If you need to make a quick fix, you can do it here.
// Later when you have proper time, you can move the
// fix into the proper structure/organization
// See https://goo.gl/uABajm
$important: config(important);

// the intercom button should be positioned above the mobile toolbar
@include mobile {
  .crisp-client .crisp-1rjpbb7[data-full-view=true] .crisp-1rf4xdh a.crisp-kquevr {
    bottom: 72px $important;
  }
  .crisp-client .crisp-1rjpbb7 {
  // z-index smaller than $gl-z-index-modal
    z-index: $gl-z-index-max $important;
  }
}

body.calendly-reset {
  overflow: initial$important;
}

.calendly-overlay {
  z-index: 200000$important;
}

#lightboxBackdrop {
  z-index: $gl-z-index-modal;
}

// GtagContainer anti-flicker function
// .async-hide { opacity: 0 !important}
