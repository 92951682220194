// Link styles

// A link that inherits the parent's text styles and has theme-primary hover color
$important: config(important);

.u-text-button {
  @include button-reset();
}

.u-text-link {
  @include button-reset();
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;

  @include hover {
    color: $gl-link-hover-color;
  }
}

.u-link {
  @include button-reset();

  color: $gl-link-color;

  @include hover {
    color: $gl-link-hover-color;
  }
}

.u-link-hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.u-accent-link {
  color: $theme-accent;
  text-decoration: underline;
  font-weight: inherit;

  @include hover {
    color: $theme-accent;
    text-decoration: none;
  }
}

.u-primary-link {
  color: $theme-primary;
  text-decoration: underline;
  font-weight: inherit;

  @include hover {
    color: $theme-primary;
    text-decoration: none;
  }
}

.u-inherit-link {
  @extend .u-text-link;

  @include hover {
    color: inherit;
  }
}


.u-pointer {
  cursor: pointer $important;
}

.u-cursor-inherit {
  cursor: inherit $important;
}

.u-cursor-auto {
  cursor: auto $important;
}

.u-cursor-default {
  cursor: default $important;
}
