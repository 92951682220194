/* ==========================================================================
   #PHRASING CONTENT
   ========================================================================== */

/**
* Phrasing content defines the text and the mark-up it contains.
* Runs of phrasing content make up paragraphs.
* See https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Content_categories#Phrasing_content
*
* Examples:
* <abbr>, <a>, <b>, <bdo>, <br>, <cite>, <code>, <data>, <dfn>, <em>, <hr>, <i>, <kbd>,
* <mark>, <meter>, <noscript>, <output>, <progress>, <q>, <ruby>, <samp>, <small>,
* <span>, <strong>, <sub>, <sup>, <time>, <var>, <video>, <wbr>
*
*/

hr {
  border-top: 1px solid $theme-light-dk;
  border-width: 1px 0 0;
  margin: $gl-spacer-xl auto;
}

p {
  margin: 0 0 rem($gl-spacer-m);
}

a {
  color: $gl-link-color;
  text-decoration: none;

  @include hover-focus-active {
    text-decoration: none;
  }
}

:hover a {
  transition: $gl-link-transition;
}

strong {
  font-weight: 700;
}

b {
  font-weight: bold;
}

mark {
  background: none;
}
