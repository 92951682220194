.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-hidden-mobile {
  @include mobile {
    overflow: hidden;
  }
}

.u-overflow-auto {
  overflow: auto;
}

.u-overflow-unset {
  overflow: unset $important;
}
