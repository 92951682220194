// Color utilities
$important: config(important);

// Gradient background
@each $name, $gradient in $theme-gradients {
  .u-gradient-#{'' + $name} {
    background-image: $gradient $important;
  }
}

.u-color-inherit {
  color: inherit $important;
}

// Colors
@each $name, $color in $theme-clrs {
  .u-color-#{'' + $name} {
    color: $color $important;
  }

  .u-color-hover-#{'' + $name} {
    @include hover {
      color: $color $important;
      transition: $gl-link-transition;
    }
  }

  .u-bg-#{'' + $name} { background-color: $color $important; }
}
