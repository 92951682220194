
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $gl-z-index-modal;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: $theme-dark-dkr;
  z-index: $gl-z-index-1;
}

.inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $gl-z-index-2;
  background: $theme-light-lt;

  max-height: 98%;
  overflow: auto;

  @include border-top-radius(16px);

  padding: $gl-spacer-m;
}


.close {
  @include button-reset();

  display: inline-flex;
  width: 48px;
  height: 33px;
  color: $theme-light-lt;
  background: $theme-dark-dkr;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}
