$important: config(important);


.u-fixed-scroll {
  -webkit-overflow-scrolling: touch $important;
  overflow: hidden $important;
}

html.u-fixed-scroll {
  height: 100% $important;
  // position: relative;
}

@include media('<sm') {
  body.u-fixed-scroll {
    height: 100% $important;
  }
}
