
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
// ==========================================================================
// #BUTTON
// ==========================================================================
// Default vars
$btn-disabled-opacity      : $gl-disabled-opacity;
$btn-border-width          : $gl-border-width-base;
$btn-line-height           : $gl-line-height-xs;
$btn-transition            : $gl-transition-base;
$btn-transition-properties : color, background-color, border-color, opacity;
$btn-border-radius         : 100px;
// Round Button
$btn-round-size            : $gl-asset-size-lg;
$btn-round-background      : $theme-dark-ltr;
$btn-round-hover-background: $theme-primary;
$btn-round-color           : $theme-light-lt;

// Default Button Size
$btn-padding-x   : 48px;
$btn-padding-y   : 12px;
$btn-font-size   : $gl-font-size-s;
$btn-line-height : $gl-line-height-xs;

$btn-full-width-padding-x: $gl-spacer-xxs;

$btn-shadow: 0 2px 8px 0 rgba($theme-dark-dkr, 0.1);

$important: config(important);


// [TODO] Update React component (add an xs size)

$btn-variants: (
  primary: (
    background      : $theme-primary,
    color           : $theme-light-lt,
    hover-background: $theme-primary-lt
  ),
  primary-alt: (
    background      : $theme-primary-alt,
    color           : $theme-light-lt,
    hover-background: $theme-primary-alt-lt
  ),
  alert: (
    background      : $theme-alert,
    color           : $theme-light-lt,
    hover-background: $theme-alert
  ),
  alt: (
    background      : $theme-light,
    color           : $theme-dark-dk,
    hover-background: $theme-dark-ltr
  ),
  fb: (
    background      : $theme-social-fb,
    color           : $theme-light-lt,
    hover-background: darken($theme-social-fb, 5%)
  ),
  google: (
    background      : $theme-light-lt,
    color           : $theme-dark-dk,
    hover-background: darken($theme-light-lt, 2%),
    border          : $theme-dark-ltr,
    border-width    : 1px
  ),
  google-dark: (
    background      : $theme-dark-dk,
    color           : $theme-light-lt,
    hover-background: lighten($theme-dark-dk, 5%),
  ),
  apple-dark: (
    background      : $theme-dark-dk,
    color           : $theme-light-lt,
    hover-background: lighten($theme-dark-dk, 5%),
  ),
  dark: (
    background       : #121212, // TODO: replace with variable
    color            : $theme-light-lt,
    hover-background : lighten($theme-dark-dk, 5%),
  ),
  blue: (
    background       : $theme-blue-dk,
    color            : $theme-light-lt,
    hover-background : lighten($theme-blue-dk, 5%),
  ),
  purple: (
    background       : $theme-purple,
    color            : $theme-light-lt,
    hover-background : lighten($theme-purple, 5%),
  ),
  light-lt: (
    background      : $theme-light-lt,
    color           : $theme-dark-dk,
    hover-background: darken($theme-light-lt, 2%),
    border          : rgba($theme-dark-dkr, 12%),
    border-width    : 1px
  )
);

$btn-outline-variants: (
  primary: (
    color           : $theme-primary,
    hover-background: $theme-primary-accent,
    border-color    : $theme-primary,
    background      : $theme-light-lt
  ),
  primary-light: (
    color           : $theme-dark-dkr,
    border-color    : $theme-primary,
    background      : #F7F9F9 // TODO: move to colors
  ),
  alt: (
    color           : $theme-dark-dk,
    hover-background: $theme-primary-ltst,
    border-color    : $theme-dark-ltr,
    background      : $theme-light-lt
  )
);

$btn-sizes: (
  xs: (
    padding-x      : 10px,
    icon-padding-x : 6px,
    padding-y      : 5px,
    font-size      : $gl-font-size-xxs,
    line-height    : $gl-line-height-xs,
  ),
  sm: (
    padding-x  : 16px,
    padding-y  : 8px,
    icon-padding-x: 4px,
    font-size  : $gl-font-size-xs,
    line-height: $gl-line-height-sm
  ),
  md: (
    padding-x  : $btn-padding-x,
    padding-y  : $btn-padding-y,
    icon-padding-x: 4px,
    font-size  : $btn-font-size,
    line-height: $btn-line-height,
    mobile: 'sm'
  ),
  lg: (
    padding-x  : 64px,
    padding-y  : 14px,
    icon-padding-x: 4px,
    font-size  : $gl-font-size-base,
    line-height: $gl-line-height-xs
  )
);

@mixin button-icon($padding-x) {
  .oc-btn__icon:not(.oc-btn__reverse) {
    margin-right: $padding-x;
  }
  .oc-btn__icon.oc-btn__reverse {
    margin-left: $padding-x;
  }
}

// Mobile size for social buttons
$social-padding-left-mobile: 35px;
$social-padding-right-mobile: 15px;

.oc-btn__btn {
  $self: &;
  $defaultSize: map-get($btn-sizes, md);

  position: relative;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: $btn-border-width solid transparent;
  background: transparent;
  border-radius: $btn-border-radius;


  font-weight: 600;
  font-family: inherit;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;

  &[type="button"] {
    -webkit-appearance: none;
  }

  // To avoid animation during the page loading before styles are loaded
  // we enable transition only for mouse events
  &:hover & {
    transition: $btn-transition;
    transition-property: $btn-transition-properties;
  }

  @include button-size(
    map-get($defaultSize, padding-y),
    map-get($defaultSize, padding-x),
    map-get($defaultSize, font-size),
    map-get($defaultSize, line-height)
  );

  @if (map-has-key($defaultSize, icon-padding-x)) {
    @include button-icon(map-get($defaultSize, icon-padding-x))
  }

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  // Disabled comes first so active can properly restyle
  &.is-disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.is-inactive {
    cursor: inherit;
  }

  @at-root {
    // Future-proof disabling of clicks on `<a>` elements
    a.is-disabled#{$self},
    fieldset[disabled] a#{$self} {
      pointer-events: none;
    }
  }
}

.oc-btn__shadowed {
  box-shadow: $btn-shadow;
}

// Sizes

@each $size, $opts in $btn-sizes {
  .oc-btn__#{$size} {
    @include button-size(
      map-get($opts, padding-y),
      map-get($opts, padding-x),
      map-get($opts, font-size),
      map-get($opts, line-height)
    );


    @if (map-has-key($opts, icon-padding-x)) {
      @include button-icon(map-get($opts, icon-padding-x))
    }

    @if (map-has-key($opts, tablet)) {
      @include tablet {
        $tabletSize: map-get($btn-sizes, map-get($opts, tablet));

        @include button-size(
          map-get($tabletSize, padding-y),
          map-get($tabletSize, padding-x),
          map-get($tabletSize, font-size),
          map-get($tabletSize, line-height)
        );

        @if (map-has-key($tabletSize, icon-padding-x)) {
          @include button-icon(map-get($tabletSize, icon-padding-x))
        }
      }
    }

    @if (map-has-key($opts, mobile)) {
      @include phone {
        $mobileSize: map-get($btn-sizes, map-get($opts, mobile));

        @include button-size(
          map-get($mobileSize, padding-y),
          map-get($mobileSize, padding-x),
          map-get($mobileSize, font-size),
          map-get($mobileSize, line-height)
        );

        @if (map-has-key($mobileSize, icon-padding-x)) {
          @include button-icon(map-get($mobileSize, icon-padding-x))
        }
      }
    }
  }

  // @if (map-has-key($opts, icon-padding-x)) {

  //   .with-icon--#{$size}:not(.oc-btn__reverse) {
  //     padding-left: map-get($opts, icon-padding-x);
  //   }

  //   .with-icon--#{$size}.oc-btn__reverse {
  //     padding-right: map-get($opts, icon-padding-x);
  //   }
  // }
}

// [TODO] Refactor Active state classes (merge button-variant and button-outline-variant)
@each $variant, $opts in $btn-variants {
  .oc-btn__#{"" + $variant},
  .oc-btn__toggle--#{"" + $variant}.is-active {
    @include button-variant(
      map-get($opts, background),
      map-get($opts, color),
      map-get($opts, hover-background)
    );

    @if (map-has-key($opts, border-width)) {
      border-width: map-get($opts, border-width);
    }

    @if (map-has-key($opts, border)) {
      &,
      &:hover,
      &:focus,
      &.is-disabled,
      &:disabled,
      &.is-inactive {
        border-color: map-get($opts, border);
      }
    }
  }
}

// Default Variant
.none {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-family: inherit;
  // TODO: https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
  outline: none;
}

@each $variant, $opts in $btn-outline-variants {
  .oc-btn__#{"" + $variant}-outline,
  .oc-btn__toggle--#{"" + $variant}-outline.is-active {
    @include button-outline-variant(
      map-get($opts, color),
      map-get($opts, hover-background),
      map-get($opts, border-color),
      map-get($opts, background)
    );
  }

  // @at-root {
  //   #{&}-toggle--outline--#{"" + $variant}.is-active {
  //     @include button-outline-variant(
  //      map-get($opts, color),
  //      map-get($opts, hover-background),
  //      map-get($opts, border-color),
  //      map-get($opts, background)
  //       $important
  //     );
  //   }
  // }
}

// Full Width Button

.oc-btn__full {
  width: 100%;
  text-align: center;
  padding-left: $btn-full-width-padding-x;
  padding-right: $btn-full-width-padding-x;
}

@include mobile {
  .oc-btn__full-mobile {
    width: 100%;
    text-align: center;
    padding-left: $btn-full-width-padding-x;
    padding-right: $btn-full-width-padding-x;
  }
}

// Round Button
.oc-btn__round {
  padding: 0;

  @include size($btn-round-size);

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background $gl-duration-base;
  background: $btn-round-background;
  color: $btn-round-color;
  font-weight: normal;

  &:hover,
  &.is-active {
    background: $btn-round-hover-background;
    color: $btn-round-color;
  }

  // https://stackoverflow.com/a/40483475/10410438
  // Safary 10.1 ignores align-items/justify-content
  // if the flex container is a button element
  &::before,
  &::after {
    content: '';
    flex: 1 0 auto;
  }

  .oc-btn__icon:not(.oc-btn__reverse),
  .oc-btn__icon.oc-btn__reverse {
    margin: 0;
  }
}



// Social button
.oc-btn__social {
  // $desktopSize: map-get($btn-sizes, md);

  border-width: $gl-border-width-base;
  font-size: $gl-font-size-s;
  font-weight: 500;

  .icon {
    margin-right: 4px;
    margin-left: -4px;
  }

  @include media('<sm') {
    padding-left: $social-padding-left-mobile;
    padding-right: $social-padding-right-mobile;
  }

  @include media('>=sm') {
    .icon {
      margin: 0;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .icon {
    margin: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.oc-btn__round-borders {
  border-radius: 24px;
}

.oc-btn__clickable {
  cursor: pointer;
}
