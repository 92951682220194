// stylelint-disable declaration-no-important

// Margin and Padding

@if (config(utilities)) {
  $prefix: 'u-';
  $important: config(important);


  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        .#{$prefix}#{$abbrev}t-0#{$infix},
        .#{$prefix}#{$abbrev}y-0#{$infix} { #{$prop}-top: 0 $important; }

        .#{$prefix}#{$abbrev}b-0#{$infix},
        .#{$prefix}#{$abbrev}y-0#{$infix} { #{$prop}-bottom: 0 $important; }

        .#{$prefix}#{$abbrev}l-0#{$infix},
        .#{$prefix}#{$abbrev}x-0#{$infix} { #{$prop}-left: 0 $important; }

        .#{$prefix}#{$abbrev}r-0#{$infix},
        .#{$prefix}#{$abbrev}x-0#{$infix} { #{$prop}-right: 0 $important; }
      }

      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {

          .#{$prefix}#{$abbrev}-#{$size}#{$infix} { #{$prop}: rem($length) $important; }

          .#{$prefix}#{$abbrev}t-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}y-#{$size}#{$infix} {
            #{$prop}-top: rem($length) $important;
          }

          .#{$prefix}#{$abbrev}r-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}x-#{$size}#{$infix} {
            #{$prop}-right: rem($length) $important;
          }

          .#{$prefix}#{$abbrev}b-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}y-#{$size}#{$infix} {
            #{$prop}-bottom: rem($length) $important;
          }

          .#{$prefix}#{$abbrev}l-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}x-#{$size}#{$infix} {
            #{$prop}-left: rem($length) $important;
          }
        }

        // Some special margin utils
        .#{$prefix}#{$abbrev}-0#{$infix} { #{$prop}: 0 $important; }
        .#{$prefix}#{$abbrev}-auto#{$infix} { #{$prop}: auto $important; }

        .#{$prefix}#{$abbrev}t-auto#{$infix},
        .#{$prefix}#{$abbrev}y-auto#{$infix} {
          #{$prop}-top: auto $important;
        }

        .#{$prefix}#{$abbrev}r-auto#{$infix},
        .#{$prefix}#{$abbrev}x-auto#{$infix} {
          #{$prop}-right: auto $important;
        }

        .#{$prefix}#{$abbrev}b-auto#{$infix},
        .#{$prefix}#{$abbrev}y-auto#{$infix} {
          #{$prop}-bottom: auto $important;
        }

        .#{$prefix}#{$abbrev}l-auto#{$infix},
        .#{$prefix}#{$abbrev}x-auto#{$infix} {
          #{$prop}-left: auto $important;
        }

        .#{$prefix}#{$abbrev}b-0-last-child#{$infix} {
          > *:last-child {
            #{$prop}-bottom: 0 $important;
          }
        }
      }


    }
  }

  // negative margin (for phone only)

  @include phone {
    @each $size, $length in $spacers {
      .u-nmx-#{$size} {
        margin-left: - rem($length);
        margin-right: - rem($length);
      }
    }
  }
}
