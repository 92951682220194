// Animation.css presets

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(.9);
  }
  70% {
    // transform: scale(1);
    box-shadow: 0 0 0 15px rgba($theme-primary, 0);
  }
    100% {
    // transform: scale(.9);
    box-shadow: 0 0 0 0 rgba($theme-primary, 0);
  }
}
