img.alignright {
  float: right;
  margin: 0 0 1em 1em
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0
}

img.aligncenter {
  display: block;
  margin: 1em auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}
