/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  @include font-size($gl-font-size-h1, $gl-line-height-sm);

  font-weight: 700;
}

h2 {
  @include font-size($gl-font-size-h2, $gl-line-height-sm);

  font-weight: 700;
}

h3 {
  @include font-size($gl-font-size-h3, $gl-line-height-sm);

  font-weight: 700;
}

h4 {
  @include font-size($gl-font-size-h4, $gl-line-height-sm);

  font-weight: 700;
}

h5 {
  @include font-size($gl-font-size-h5, $gl-line-height-sm);

  font-weight: 700;
}

h6 {
  @include font-size($gl-font-size-h6, $gl-line-height-sm);

  font-weight: 700;
}
