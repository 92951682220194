@if (config(utilities)) {
  $prefix: 'u-';
  $important: config(important);




  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .u-font-size#{$infix}-inherit {
        font-size: inherit;
      }

      @each $name, $size in $font-sizes {
        .u-font-size#{$infix}-#{'' + $name} {
          font-size: rem($size) $important;
        }
      }

      @each $name, $size in $gl-header-font-sizes {
        .u-font-size#{$infix}-#{'' + $name} {

          @if ($important == null) {
            @include font-size($size, auto);
          } @else {
            @include font-size($size, auto, true);
          }
        }
      }
    }
  }
}
