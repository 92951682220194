
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  margin: $gl-spacer-3xl auto;
  height: auto;
  min-height: 50vh;
  text-align: center;

  img {
    margin-bottom: $gl-spacer-2xl;
  }

  p {
    font-size: $gl-font-size-base;
    color: $theme-dark;
    margin: 0 0 5px;
  }
}

.push {
  height: $gl-spacer-4xl;
}
