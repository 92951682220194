/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  font-size: 100%;
  // overflow-y: scroll; /* [1] */
  min-height: 100%; /* [2] */
  width: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

body {
  line-height: $gl-line-height-base;
  font-family: $gl-font-family-base;
  font-size: rem($gl-font-size-s);
  // [TODO] remove font-size $gl-font-size-s from components
  color: $theme-dark-dk;
  background: $theme-light-lt;
  width: 100%;
  position: relative;
  min-height: 100%;
  // overflow: hidden;
}


// Page Specific styles

.hw-page {
  // font-family: 'Lato', sans-serif;
  // letter-spacing: 0.01em;
  overflow-y: scroll;
  overflow-x: auto;
}
