
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    

/* ==========================================================================
   #GRID
   ========================================================================== */

/**
* Bootstrap 4 grid system
*/

$prefix: 'l-';

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if config('gridClasses') {
  .#{$prefix}container {
    @include make-container();
    @include make-container-max-widths();
  }

  .#{$prefix}container-top {
    @include mobile {
      padding-left: $gl-container-mobile-padding;
      padding-right: $gl-container-mobile-padding;
    }
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if config('gridClasses') {
  .#{$prefix}container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if config('gridClasses') {
  .#{$prefix}row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .#{$prefix}col,
    > .#{$prefix}col-base,
    > [class*="#{$prefix}col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @each $size, $gap in $gl-grid-gaps {
        .#{$prefix}row-gap#{$infix}--#{$size} {
          @include make-row-gap($gap);

          > .#{$prefix}col,
          > .#{$prefix}col-base,
          > [class*="#{$prefix}col-"] {
            @include make-col-padding(math.div($gap, 2));
          }
        }
      }
    }

  }



}

// Columns
//
// Common styles for small and large grid columns

@if config('gridClasses') {
  @include make-grid-columns();
}
