
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
// :global {

  div.tox-tinymce-aux {
    z-index: $gl-z-index-modal;
  }

  div.tox {
    &:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
      border: none;
    }

    .tox-dialog-wrap {
      z-index: $gl-z-index-modal;
    }

    .tox-dialog {
      width: 900px;
      max-width: 95%;
      max-height: 80%;
      overflow: auto;

      @include media("height<940px") {
        height: 100%;
      }
    }

    .tox-editor-container {
      flex-direction: column-reverse;
    }

    .tox-editor-header {
      border: none;
    }

    .tox-toolbar__primary {
      background: none;
    }

    // FORMULA

    .tinymce-formula-iframe {
      width: 100%;
      height: 600px;
      overflow: auto;

      @include media("height<940px") {
        height: 400px;
      }
    }

    .tinymce-attachment-icon {
      @include size(20px);

      display: inline-block;
      vertical-align: middle;
    }

    .tox-form__group {
      @include media("height<940px") {
        height: 100%;
      }
    }

  }

// }
