.table {
  overflow: auto;
  width: 100%;

  table {
    width: 100%;

    td, th {
      padding: $gl-spacer-xxs;
    }

    &, th, td {
      border: 1px solid $theme-dark-ltr;
    }
  }
}
