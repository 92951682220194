$important: config(important);

$default-border-color: $theme-light-dk;

// Border Radius
.u-border-r-0 {
  @include border-radius(0, $important);
}

.u-border-t-r-0 {
  @include border-top-radius(0, $important);
}

.u-border-r-r-0 {
  @include border-right-radius(0, $important);
}

.u-border-b-r-0 {
  @include border-bottom-radius(0, $important);
}

.u-border-l-r-0 {
  @include border-left-radius(0, $important);
}

.u-border-transparent {
  border-color: transparent;
}

// Border

@if (config(utilities)) {
  $prefix: 'u-';

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .#{$prefix}border-transparent#{$infix} {
        border-color: transparent $important;
      }

      .#{$prefix}border-none#{$infix} {
        border: none $important;
      }


      .#{$prefix}border-t#{$infix} {
        border-top: $gl-border-width-base solid $default-border-color $important;
      }

      .#{$prefix}border-t-none#{$infix} {
        border-top: none $important;
      }

      .#{$prefix}border-r#{$infix} {
        border-right: $gl-border-width-base solid $default-border-color $important;
      }

      .#{$prefix}border-r-none#{$infix} {
        border-right: none $important;
      }

      .#{$prefix}border-b#{$infix} {
        border-bottom: $gl-border-width-base solid $default-border-color $important;
      }

      .#{$prefix}border-b-none#{$infix} {
        border-bottom: none $important;
      }

      .#{$prefix}border-l#{$infix} {
        border-left: $gl-border-width-base solid $default-border-color $important;
      }

      .#{$prefix}border-l-none#{$infix} {
        border-left: none $important;
      }

    }
  }
}
