$important: config(important);

@if (config(utilities)) {
  $prefix: 'u-';

  .#{$prefix}text-capitalize {
    text-transform: capitalize;
  }

  .#{$prefix}text-nowrap {
    white-space: nowrap;
  }

  .#{$prefix}text-uppercase {
    text-transform: uppercase;
  }

  .#{$prefix}text-transform-none {
    text-transform: none !important;
  }

  .#{$prefix}text-underline {
    text-decoration: underline;
  }

  .#{$prefix}text-ellipsis {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 100% !important;
    display: block !important;
  }

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .#{$prefix}text-center#{$infix} {
        text-align: center $important;
      }

      .#{$prefix}text-left#{$infix} {
        text-align: left $important;
      }

      .#{$prefix}text-right#{$infix} {
        text-align: right $important;
      }
    }
  }
}
