
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$header-height: 64px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  z-index: $gl-z-index-modal;
  background: $theme-light-lt;

  max-width: 100%;
}
.header {
  padding: 17px 20px;
}

.header_text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -11px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.408px;
}

.content {
  padding: 0 $gl-spacer-m;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_inner {
  display: flex;

  transform: translateY(math.div(-$header-height, 2));
}
