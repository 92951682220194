
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  left: 0;
  bottom: 0;

  @include mobile {
    bottom: 70px;
  }

  z-index: $gl-z-index-max;
  font-size: $gl-font-size-xxs;
  padding: $gl-spacer-xxs;
  position: fixed;
  color: white;
  border: solid 1px $theme-dark-dk;
  border-bottom: none;
  background: rgba($theme-dark-dkr, 0.7);
}

.link {
  color: white;
  margin-left: $gl-spacer-xxs;
  @include hover-focus {
    color: $theme-primary;
  }
}
