// stylelint-disable declaration-no-important

// Margin and Padding

$important: config(important);

@if (config(utilities)) {
  $prefix: 'u-';

  // https://css-tricks.com/flexbox-truncated-text/
  .#{$prefix}min-w-0 {
    min-width: 0;
  };

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .#{$prefix}w-100#{$infix} {
        width: 100% $important;
      }

      .#{$prefix}w-auto#{$infix} {
        width: auto $important;
      }

      .#{$prefix}h-100#{$infix} {
        height: 100% $important;
      }

      .#{$prefix}h-100vh#{$infix} {
        height: 100vh $important;
      }

      .#{$prefix}min-h-100vh#{$infix} {
        min-height: 100vh $important;
      }

      .#{$prefix}h-auto#{$infix} {
        height: auto $important;
      }

      @each $prop, $abbrev in (width: w, height: h) {
        @each $sizeName, $size in $asset-size {
          .#{$prefix}#{$abbrev}-#{$sizeName}#{$infix},
          .#{$prefix}size-#{$sizeName}#{$infix} { #{$prop}: rem($size) $important; }
        }
      }
    }
  }
}
